import { REQUEST_CATEGORY, RECEIVE_CATEGORY } from '../constants/ActionTypes';

const category = (
  state = {
    isFetching: false,
    didInvalidate: false,
    content: []
  },
  action
) => {
  switch (action.type) {
    case REQUEST_CATEGORY:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false
      };
    case RECEIVE_CATEGORY:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        content: action.content,
        lastUpdated: action.receivedAt
      };
    default:
      return state;
  }
};

const categories = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_CATEGORY:
    case RECEIVE_CATEGORY:
      return {
        ...state,
        [action.category]: category(state[action.category], action)
      };
    default:
      return state;
  }
};

export default categories;
