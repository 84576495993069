import React from "react";
import formatDate from "../helper";

const Education = ({educations}) => (
  <section id="Education" className="border-bottom">
    <div className="container">
      <div className="row centered">
        <h3>Formations</h3>
        <hr className="title-divider"/>
      </div>
      <div className="row">
        <div className="accordion">
          {educations.map((education) => (
            <div className="tab clearfix" key={`education-${education.title}`}>
              <div
                className="time-range"
                dangerouslySetInnerHTML={formatDate(
                  education.dateStart,
                  education.dateEnd
                )}
              />
              <div className="description">
                <h4>{education.title}</h4>
                <h5>{education.institutionName} | {education.city}</h5>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  </section>
);

export default Education;
