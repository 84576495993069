export const apiGateway = {
  uri: process.env.REACT_APP_API_URI || 'http://localhost:8080/workspaces/5dda4b19bbccb6245a9c15c5'
};

const defaultConfiguration = {
  defaultAppName: process.env.REACT_APP_DEFAULT_NAME || '',
  maxAttachmentSize: process.env.REACT_APP_MAX_ATTACHMENT_SIZE || 5000000,
};

export default defaultConfiguration;
