import React from "react";
import {apiGateway} from "../config";

const Profile = ({title, summary, pdf, image}) => (
  <section id="Profile" name="Profile no-border" className="border-bottom">
    <div className="container">
      <div className="row col-md-8 col-md-offset-2 text-center">
        {image && (
          <img
            className="img-profile img-circle"
            src={`${apiGateway.uri}/images/${image}?width=180`}
            alt=""
          />
        )}
        <h2 dangerouslySetInnerHTML={{__html: title}}/>
        <hr/>
        <p dangerouslySetInnerHTML={{__html: summary}}/>
        {pdf && (
          <a
            className="btn btn-primary btn-lg btn-outlined"
            href={pdf}
            rel="noopener noreferrer"
          >
            <i className="icon-doc"/>
            &nbsp;Télécharger mon CV
          </a>
        )}
      </div>
    </div>
  </section>
);

export default Profile;
