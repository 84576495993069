import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {fetchCategoryIfNeeded} from '../actions';
import SkillComponent from '../components/Skill';
import '../resources/css/Skill.css';

const Skill = ({skillCategories, dispatch}) => {
  useEffect(() => {
    dispatch(fetchCategoryIfNeeded('skills', { filters: 'disabled:false' }));
  }, [dispatch]);

  return (
    <SkillComponent skillCategories={skillCategories} />
  );
}

const mapStateToProps = state => {
  const {skills} = state;

  if (skills && skills.content && skills.content.length) {
    return {
      skillCategories: skills.content
    };
  }

  return {
    skillCategories: []
  };
};

export default connect(mapStateToProps)(Skill);
