import React from 'react';
import '../resources/css/Footer.css';

const links = [
  {
    name: 'github',
    url: 'https://github.com/gitsignore'
  },
  {
    name: 'linkedin',
    url: 'https://www.linkedin.com/in/maxime-signoret'
  },
  {
    name: 'twitter',
    url: 'https://twitter.com/MaximeSignoret'
  }
];

const Footer = () => (
  <footer>
    <div className="container">
      <p>
        <span>©{new Date().getFullYear()} Maxime Signoret</span>
        <span className="pull-right">
          {links.map(({name, url}) => (
              <a
                  key={name}
                  href={url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={`icon-social-${name} icon-spacer icon-size`}
              >
                <span className="hidden">{name}</span>
              </a>
          ))}
        </span>
      </p>
    </div>
  </footer>
);

export default Footer;
