import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {fetchCategoryIfNeeded} from '../actions';
import EducationComponent from '../components/Education';
import '../resources/css/Education.css';

const Education = ({educations, dispatch}) => {
  useEffect(() => {
    dispatch(fetchCategoryIfNeeded('educations', { filters: 'disabled:false', sort: '-dateStart' }));
  }, [dispatch]);

  return (
    <EducationComponent educations={educations} />
  );
}

const mapStateToProps = state => {
  const {educations} = state;

  if (educations && educations.content && educations.content.length) {
    return {
      educations: educations.content
    };
  }

  return {
    educations: []
  };
};

export default connect(mapStateToProps)(Education);
