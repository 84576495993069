import { DateTime } from 'luxon';

const formatDate = (unformattedStartDate, unformattedEndDate) => {
  const currentDate = DateTime.now().setLocale('fr');
  const startDate = DateTime.fromISO(unformattedStartDate);
  const endDate = DateTime.fromISO(unformattedEndDate);

  const formattedStartDate = `${startDate.toLocaleString({year: 'numeric'})} <span> / ${startDate.toLocaleString({month: 'short'})}</span>`;

  if (endDate.isValid && endDate < currentDate) {
    return {
      __html: `${formattedStartDate} - ${endDate.toLocaleString({year: 'numeric'})} <span> / ${endDate.toLocaleString({month: 'short'})}</span>`,
    };
  }

  return { __html: `${formattedStartDate} - Aujourd'hui` };
}

export default formatDate;
