import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { register } from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import store from './stores';
import Navbar from './containers/Navbar';
import Header from './containers/Header';
import Profile from './containers/Profile';
import Experience from './containers/Experience';
import Skill from './containers/Skill';
import Education from './containers/Education';
import Footer from './components/Footer';
import './resources/css/index.css';

render(
  <Provider store={store}>
    <div className="App">
      <Header />
      <Navbar />
      <Profile />
      <Experience />
      <Skill />
      <Education />
      <Footer />
    </div>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
