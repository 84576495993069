import React from 'react';
import {apiGateway} from '../config';
import {Link} from "react-scroll";

const Header = ({title, subtitle, image}) => {
  const sectionStyle = {
    background: `#31404e no-repeat fixed center top / cover ${image ? `url(${apiGateway.uri}/images/${image}?width=${window.innerWidth})` : ''}`
  };

  return (
    <section id="Header" name="Header" style={sectionStyle}>
      <div className="overlay">
        <div className="header">
          <div className="header_center">
            <ul>
              <li>
                <h2 className="thin">{title}</h2>
                <p className="thin" dangerouslySetInnerHTML={{__html: subtitle}}/>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Link id="scroll-down" to="Navbar" spy smooth duration={1000}>
        <span className="icon-mouse"/>
      </Link>
    </section>
  );
}

export default Header;
