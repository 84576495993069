import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {fetchCategoryIfNeeded} from '../actions';
import ExperienceComponent from '../components/Experience';
import '../resources/css/Experience.css';

const Experience = ({experiences, dispatch}) => {
  useEffect(() => {
    dispatch(fetchCategoryIfNeeded('experiences', { filters: 'disabled:false', sort: '-dateStart' }));
  }, [dispatch]);

  return (
    <ExperienceComponent experiences={experiences} />
  );
}

const mapStateToProps = state => {
  const {experiences} = state;

  if (experiences && experiences.content && experiences.content.length) {
    return {
      experiences: experiences.content
    };
  }

  return {
    experiences: []
  };
};

export default connect(mapStateToProps)(Experience);
