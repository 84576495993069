import React from "react";

const Skill = ({skillCategories}) => (
  <section id="Skill" name="Skill" className="border-bottom">
    <div className="container">
      <div className="row centered">
        <h3>Compétences</h3>
        <hr className="title-divider"/>
      </div>
      <div className="row">
        {skillCategories.map(skillCategory => (
          <div
            id="skill-block-1"
            className="col-xs-12 col-md-10 col-md-offset-1 skill-bar"
            key={`skillCategory-${skillCategory.title}`}
          >
            <h4 className="text-center">{skillCategory.title}</h4>
            {skillCategory.skills.map(skill => (
              <div key={`skill-${skill.title}`}>
                <span>{skill.title}</span>
                <div className="progress">
                  <div
                    className="level"
                    style={{width: `${skill.level}%`}}
                  >
                    <i className="fa fa-circle"/>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  </section>
);

export default Skill;
