import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {fetchCategoryIfNeeded} from '../actions';
import ProfileComponent from '../components/Profile';
import '../resources/css/Profile.css';

const Profile = ({title, summary, image, pdf, dispatch}) => {
  useEffect(() => {
    dispatch(fetchCategoryIfNeeded('profiles', { filters: 'disabled:false' }));
  }, [dispatch]);

  return (
    <ProfileComponent title={title} summary={summary} image={image} pdf={pdf} />
  );
}

const mapStateToProps = state => {
  const {profiles} = state;

  if (profiles && profiles.content && profiles.content.length) {
    return {
      ...profiles.content[0]
    };
  }

  return {
    title: 'Maxime Signoret',
    summary: null,
    image: null,
    pdf: null
  };
};

export default connect(mapStateToProps)(Profile);
