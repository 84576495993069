import { get } from 'axios';
import { apiGateway } from '../config';
import { REQUEST_CATEGORY, RECEIVE_CATEGORY } from '../constants/ActionTypes';

export const requestCategory = category => ({
  type: REQUEST_CATEGORY,
  category
});

export const receiveCategory = (category, content) => ({
  type: RECEIVE_CATEGORY,
  category,
  content,
  receivedAt: Date.now()
});

const buildRequestOptions = (options = {}) => {
  const optionsArray = Object.keys(options).map(key => `${key}=${options[key]}`);

  if (optionsArray.length !== 0) {
    return `?${optionsArray.join('&')}`;
  }

  return '';
};

const fetchCategory = (category, options = {}) => async dispatch => {
  dispatch(requestCategory(category));

  try {
    const { data } = await get(
      `${apiGateway.uri}/${category.replace(/([A-Z])/g, '-$1')}${buildRequestOptions(options)}`
    );
    localStorage.setItem(category, JSON.stringify(data));

    return dispatch(receiveCategory(category, data));
  } catch (err) {
    dispatch(
      receiveCategory(category, JSON.parse(localStorage.getItem(category)))
    );
  }
};

const shouldFetchCategory = (state, category) => {
  const currentCategory = state[category];
  if (!currentCategory) {
    return true;
  }
  if (currentCategory.isFetching) {
    return false;
  }
  return currentCategory.didInvalidate;
};

export const fetchCategoryIfNeeded = (category, options = {}) => (dispatch, getState) => {
  if (shouldFetchCategory(getState(), category)) {
    return dispatch(fetchCategory(category, options));
  }
};
