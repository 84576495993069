import React, { Component } from 'react';
import ReactDOM, { findDOMNode } from 'react-dom';
import $ from 'jquery';
import { connect } from 'react-redux';
import { Link, animateScroll } from 'react-scroll';
import { titles } from '../constants/navbar';
import '../resources/css/Navbar.css';

class Navbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isFixed: false
    };
    this.fixedNav = this.fixedNav.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.fixedNav);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.fixedNav);
  }

  handleToggle = () => {
    const el = findDOMNode(this.refs.nav);
    $(el).slideToggle();
  };

  fixedNav() {
    if (this.props.display) {
      this.setState({
        isFixed: ReactDOM.findDOMNode(this).getBoundingClientRect().top <= 0
      });
    }
  }

  render() {
    const isSticky = this.state.isFixed
      ? 'sticky-wrapper is-sticky'
      : 'sticky-wrapper';
    const style = this.state.isFixed ? { position: 'fixed', top: 0 } : {};
    const { title, entries, display } = this.props;

    return (
      display && (
        <div id="navbar-sticky-wrapper" name="Navbar" className={isSticky}>
          <header className="clearfix" id="navbar" style={style}>
            <h1 className="navbar-title" onClick={animateScroll.scrollToTop}>
              {title}
            </h1>
            {entries.length > 0 && (
              <div
                id="nav-button"
                className="icon-menu"
                onClick={this.handleToggle}
              />
            )}
            <nav>
              <ul id="nav" ref="nav">
                {entries.map(entry => (
                  <li key={entry.id}>
                    <Link
                      to={entry.id}
                      spy
                      smooth
                      duration={1000}
                      offset={-100}
                      onClick={this.handleToggle}
                    >
                      {entry.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </nav>
          </header>
        </div>
      )
    );
  }
}

const mapStateToProps = state => {
  const entries = [];
  Object.keys(titles).map(
    title =>
      state[title] &&
      state[title].content &&
      state[title].content.length &&
      entries.push({
        ...titles[title]
      })
  );

  return {
    title: 'Maxime Signoret',
    entries,
    display: !!entries.length
  };
};

export default connect(mapStateToProps)(Navbar);
