import React from "react";
import formatDate from "../helper";

const Experience = ({experiences}) => (
  <section id="Experience" className="border-bottom">
    <div className="container">
      <div className="row centered">
        <h3>Expériences</h3>
        <hr className="title-divider"/>
      </div>
      <div className="row">
        <div className="accordion">
          {experiences.map((experience, i) => (
            <div
              className="tab clearfix closed"
              tabIndex={0}
              data-id={i}
              key={`experience-${experience.title}`}
            >
              <div
                className="time-range"
                dangerouslySetInnerHTML={formatDate(
                  experience.dateStart,
                  experience.dateEnd
                )}
              />
              <div className="description">
                <h4>{experience.companyName}</h4>
                <h5>{experience.title}</h5>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  </section>
);

export default Experience;
