import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import HeaderComponent from '../components/Header';
import {fetchCategoryIfNeeded} from '../actions';
import '../resources/css/Header.css';

const Header = ({title, subtitle, image, dispatch}) => {
  useEffect(() => {
    dispatch(fetchCategoryIfNeeded('headers', { filters: 'disabled:false' }));
  }, [dispatch]);

  return (
    <HeaderComponent title={title} subtitle={subtitle} image={image}/>
  );
}

const mapStateToProps = state => {
  const {headers} = state;

  if (headers && headers.content && headers.content.length) {
    return {
      ...headers.content[0]
    };
  }

  return {};
};

export default connect(mapStateToProps)(Header);
